'use strict';

import {NetworkClient} from './NetworkClient';
import {UserStore} from '../store/UserStore';
import {Application} from '../app/Application';

let _instance = null;

class AuthClient extends NetworkClient {
    constructor() {
        super();
    }

    static getInstance() {
        if (!_instance) {
            _instance = new AuthClient();
        }

        return _instance;
    }

    _getServiceBase() {
        return 'auth';
    }

    confirmAccount(code, password, confirmPassword) {
        return this.post('user/confirm', {
            code: code,
            password: password,
            confirm: confirmPassword
        });
    }

    resetPassword(code, password, confirmPassword) {
        return this.post('password/reset', {
            code: code,
            password: password,
            confirm: confirmPassword
        });
    }

    getOnetimeToken() {
        return this.get('onetime');
    }

    login(email, password) {
        let target = Application.getInstance().getConfig().appType;
        return this.post('login', {
            username : email,
            password : password,
            target: target
        });
    }

    loginWithToken(token) {
        return this.post('login/validate', {
            target: Application.getInstance().getConfig().appType,
            access: token
        });
    }

    /**
     * @deprecated Use `@totalpave/fe-auth-client` instead
     */
    whoami() {
        return this.get('whoami');
    }

    renewToken() {
        return this._renewToken();
    }

    requestPasswordReset(email) {
        return this.post('password/forgot', {
            email : email
        });
    }

    getUsers() {
        return this.get('user/list');
    }

    changePassword(current, password) {
        return this.post('password/update', {
            current: current,
            password: password
        });
    }

    updateUserSettings(settings) {
        return this.post('user/settings', {
            settings: settings
        });
    }

    createUser(firstName, lastName, email, role) {
        return this.post('user/add', {
            user : {
                fname: firstName,
                lname: lastName,
                email : email,
                role : role,
                organization: UserStore.getInstance().getOrganizationName()
            }
        });
    }

    updateUsers(users) {
        return this.post('user/update/list', {
            users: users
        });
    }

    deleteUsers(userIDs) {
        return this.post('user/delete/list', {
            users : userIDs
        });
    }

    changeEmail(email) {
        return this.post('user/update/email', {
            email: email
        });
    }

    /**
     * Returns {result:boolean} where result is true if the code is activated
     * @param {string} code 
     */
    getActivationCodeState(code) {
        return this.get(`code/state/${code}`);
    }
}

export { AuthClient };

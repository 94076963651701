
import {Action} from '@totalpave/actions';
import {UserStore} from '../store/UserStore';
import {TPError} from '@totalpave/error';

//Tells the app it's time to start the logout process
class RequestLogoutActionSingleton extends Action<void, void> {
    public override getTag(): string {
        return 'request_logout_action';
    }

    protected override _shouldDispatchOnError(): boolean {
        return false;
    }

    /**
     *    This will start the logout process
     * 
     * @return {void} 

     */
    protected override async _execute(): Promise<void> {
        // requestlogoutaction can't really find out if the data in user store is valid. So at best we 
        // can only log scenarios where we are requesting logging out while already logged out.
        
        // We can't simply stop this scenario; because, we don't want to stop a niche bug case and create 
        // more niche bug cases; because, the app can't log out due to invalid state.
        if (!UserStore.getInstance().getUser()) {
            new TPError("RequestLogoutAction was called when User is already logged out.").report();
        }
    }
}

const RequestLogoutAction = new RequestLogoutActionSingleton();

export { RequestLogoutAction };

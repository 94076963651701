
import * as React from 'react';

export interface IIntervalProps {
    interval: number;
    onTick: () => Promise<void>;
    paused?: boolean;
};

/**
 * A component that doesn't actually render anything,
 * but will trigger a callback every so often.
 * 
 * Makes use of component lifecycle to 
 * start/remove itself when it is mounted/unmounted
 */
export class Interval extends React.Component<IIntervalProps> {
    private $isUnmounting: boolean;
    private $timerRef: number;

    constructor(props: IIntervalProps) {
        super(props);
        this.$timerRef = null;
        this.$isUnmounting = null;
    }

    public override componentDidMount(): void {
        // Reset member in the event of unmount + remount.
        this.$isUnmounting = false;
        this._doTick();
    }

    protected _doTick(): void {
        if (this.$isUnmounting) {
            return;
        }
        this.$timerRef = window.setTimeout(() => {
            this._runTick().then(() => {
                this._doTick();
            }).catch(() => {
                this._doTick();
            });
        }, this.props.interval);
    }

    protected _runTick(): Promise<void> {
        if (this.props.paused) {
            return Promise.resolve();
        }
        
        let promise = this._tick();
        if (!(promise instanceof Promise)) {
            promise = Promise.resolve();
        }
        return promise;
    }

    public trigger(): void {
        window.clearTimeout(this.$timerRef);
        this._runTick().then(() => {
            this._doTick();
        }).catch(() => {
            this._doTick();
        });
    }

    public override componentWillUnmount(): void {
        this.$isUnmounting = true;
        window.clearTimeout(this.$timerRef);
    }

    protected _tick(): Promise<void> {
        return this.props.onTick();
    }

    public override render(): React.ReactNode {
        return null;
    }
}

'use strict';

import {Store} from '@totalpave/store';
import {Currency} from '@totalpave/currency';
import {NumberGroupStyle} from '../utils/NumberGroupStyle';
import {Immutable} from '../utils/Immutable';

let _instance = null;

export class LocalizationStore extends Store {
    constructor() {
        super();
        this.$hasLoadedLocalization = false;
        this.$currencySettings = {
            locale: 'en-US',
            options: {
                style: 'currency',
                currency: Currency.US_DOLLAR,
                currencyDisplay: this.$getCurrencyDisplay()
            }
        };
        this.$currencyFormat = null;
    }

    $getCurrencyDisplay() {
        return 'narrowSymbol';
    }

    static getInstance() {
        if (!_instance) {
            _instance = new LocalizationStore();
        }
        return _instance;
    }

    hasLoadedLocalization() {
        return this.$hasLoadedLocalization;
    }

    getCurrencyFormat() {
        if (this.$currencyFormat === null) {
            let parts = Intl.NumberFormat(this.$currencySettings.locale, this.$currencySettings.options).formatToParts(100000000);
            let format = {
                prefixSymbol: "",
                suffixSymbol: "",
                groupSeparator: "",
                groupStyle: NumberGroupStyle.THOUSAND,
                decimalSeparator: "",
                decimalScale: 0
            };
            for (let i = 0, length = parts.length; i < length; ++i) {
                switch (parts[i].type) {
                    case "group": 
                        format.groupSeparator = parts[i].value;
                        break;
                    case "decimal": 
                        format.decimalSeparator = parts[i].value;
                        break;
                    case "fraction": 
                        format.decimalScale = parts[i].value.length;
                        break;
                    case "currency":
                        if (i === 0) {
                            format.prefixSymbol = parts[i].value;
                        }
                        else {
                            format.suffixSymbol = parts[i].value;
                        }
                        break;
                }
            }
            this.$currencyFormat = new Immutable(format);
        }
        return this.$currencyFormat;
    }

    _update(actionData) {
        let data = actionData.getData();
        switch (actionData.getTag()) {
            case 'load-currency-localization':
                this.$hasLoadedLocalization = true;
                this.$currencySettings = {
                    locale: data.locale,
                    options: {
                        style: 'currency',
                        currency: data.currency,
                        currencyDisplay: this.$getCurrencyDisplay()
                    }
                };
                this.$currencyFormat = null;
                return true;
        }

        return false;
    }
}


import {Action} from '@totalpave/actions';

export interface IFlagUpdateActionInput {
    name: string;
    value: boolean;
}

export interface IFlagUpdateActionOutput {
    flagName: string;
    flagValue: boolean;
}

/*
Used to create new distresses for a sample.
 */
class FlagUpdateActionSingleton extends Action<IFlagUpdateActionInput, IFlagUpdateActionOutput> {

    public override getTag(): string {
        return 'flag_update';
    }

    protected override _shouldDispatchOnError(): boolean {
        return false;
    }

    /**
     * public execute
     * 
     * @param  {String}  name       Flag Name
     * @param  {Boolean} value
     * @return {void}          
     */
    protected override async _execute(args: IFlagUpdateActionInput): Promise<IFlagUpdateActionOutput> {
        return {
            flagName: args.name,
            flagValue: args.value
        };
    }
}

const FlagUpdate = new FlagUpdateActionSingleton();

export { FlagUpdate };

'use strict';

import {Store} from '@totalpave/store';

let _instance = null;

export class ProgressStore extends Store {
    constructor() {
        super();

        this._progress = {};
    }

    getProgress(id) {
        return this._progress[id];
    }

    static getInstance() {
        if (!_instance) {
            _instance = new ProgressStore();
        }
        return _instance;
    }

    async _reset() {
        this._progress = {};
    }

    _update(actionData) {
        let data = actionData.getData();
        switch (actionData.getTag()) {
            case 'update_progress_action':
                this._progress[data.id] = data.value;
                return true;
            case 'clear_progress_action':
                delete this._progress[data.id];
                return true;
        }

        return false;
    }
}

'use strict';

import * as React from 'react';
import {RequestLogoutAction} from '../actions/RequestLogoutAction';
import {
    View,
    IViewProps,
    IViewStylesheet
} from '@breautek/router';
import '../style/BaseScreen.less';

export interface IBaseScreenProps extends IViewProps {}

/*
Base class that represents a page that can be navigated to.
 */
export abstract class BaseScreen<TProps extends IBaseScreenProps = IBaseScreenProps> extends View<TProps> {
    protected _navigatable: boolean;

    constructor(props: TProps) {
        super(props);
        this._navigatable = true;
        
        this.state = {};

        if (!this.isNavigatable()) {
            this._navigatable = false;
            this._handleNotNavigatable();
        }
    }

    /**
     * public getClassName
     * @return {String} The CSS class of this screen.
     */
    public abstract getClassName(): string;

    public getScreenStyle(): IViewStylesheet {
        return null;
    }

    public isNavigatable(): boolean {
        return true;
    }

    protected _handleNotNavigatable(): void {
        RequestLogoutAction.execute();
    }

    /**
     * public render
     * @return {JSXElement}
     */
    protected abstract _render(): React.ReactNode;

    public override getViewStylesheet(): IViewStylesheet {
        return this.getScreenStyle();
    }

    public override _renderView(): React.ReactNode {
        return (
            this._navigatable ?
                (<div className={"Screen " + this.getClassName()}>
                    {this._render()}
                </div>)
                : null
        );
    }
}

'use strict';

import * as React from 'react';
import PropTypes from 'prop-types';

/**
 * A component that doesn't actually render anything,
 * but will trigger a callback every so often.
 * 
 * Makes use of component lifecycle to 
 * start/remove itself when it is mounted/unmounted
 * 
 * @deprecated Use ui-core instead
 */
export class Interval extends React.Component {
    constructor(props) {
        super(props);
        this._timerRef = null;
        // initialized in DidMount
        this._isUnmounting;
    }

    componentDidMount() {
        // Reset member in the event of unmount + remount.
        this._isUnmounting = false;
        this._doTick();
    }

    _doTick() {
        if (this._isUnmounting) {
            return;
        }
        this._timerRef = window.setTimeout(() => {
            this._runTick().then(() => {
                this._doTick();
            }).catch(() => {
                this._doTick();
            });
        }, this.props.interval);
    }

    _runTick() {
        if (this.props.paused) {
            return Promise.resolve();
        }
        
        let promise = this._tick();
        if (!(promise instanceof Promise)) {
            promise = Promise.resolve();
        }
        return promise;
    }

    trigger() {
        window.clearTimeout(this._timerRef);
        this._runTick().then(() => {
            this._doTick();
        }).catch(() => {
            this._doTick();
        });
    }

    componentWillUnmount() {
        this._isUnmounting = true;
        window.clearTimeout(this._timerRef);
    }

    _tick() {
        return this.props.onTick();
    }

    render() {
        return null;
    }
}

Interval.propTypes = {
    interval: PropTypes.number.isRequired,
    onTick: PropTypes.func.isRequired,
    paused: PropTypes.bool
};

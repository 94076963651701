
import {Store} from '@totalpave/store';
import {UnitMode} from '@totalpave/math';
import {
    Role,
    OrganizationAccessLevel,
    SamplingMethod,
    IStorageStrategy,
    IAuthorizedUser,
    Country,
    Currency,
    DefaultView,
    ISentryClient,
    PCIStandard,
    IPCIConfigurationMap
} from '@totalpave/interfaces';
import {Position} from '../model/Position';
import {ApplicationInstance} from '@totalpave/application-instance';
import {WebStorageStrategy} from '@totalpave/storage';
import {TPError} from '@totalpave/error';
import { IActionData, ITPError } from '@totalpave/finterfaces';
import { FlagUpdate, IFlagUpdateActionOutput } from '../actions/FlagUpdate';
import { IResetPasswordOutput, ResetPassword } from '../actions/ResetPassword';
import { ConfirmAccount, IConfirmAccountOutput } from '../actions/ConfirmAccount';
import { ILoginActionOutput, LoginAction } from '../actions/LoginAction';
import { AccessTokenUpdate } from '@totalpave/network';
import { LogoutAction } from '../actions/LogoutAction';
import { RequestLogoutAction } from '../actions/RequestLogoutAction';
import { ModifyUsers } from '../actions/ModifyUsers';

const USER_KEY: string = "user";
const USER_FLAGS_KEY: string = "user_flags";

/*
A datastore containing the users profile data and authentication token.
*/
export class UserStore extends Store {
    private static $instance: UserStore;

    private $user: IAuthorizedUser;
    private $shouldLogout: boolean;
    private $unit: UnitMode;
    private $organizationUsers: unknown[];

    public constructor() {
        super();

        this.migrateStorageSystem = this.migrateStorageSystem.bind(this);

        this.$user = null;
        this.$shouldLogout = false;
        this.$unit = UnitMode.METRIC;
        this.$organizationUsers = [];
    }

    public async initialize(): Promise<void> {
        let storage: IStorageStrategy = this.$getStorageStrategy();
        if (!storage) {
            throw new TPError('Application has not properly setup an StorageStrategy.');
        }

        await storage.initialize();

        let user: string = storage.get(USER_KEY);

        if (user) {
            this.$user = JSON.parse(user);
            this.$unit = this.$user.organization.settings.units;
        }
    }

    public static getInstance(): UserStore {
        if (!UserStore.$instance) {
            UserStore.$instance = new UserStore();
        }
        return UserStore.$instance;
    }

    public async migrateStorageSystem(): Promise<void> {
        if (localStorage.user || sessionStorage.user) {
            let storage: 'localStorage' | 'sessionStorage' = localStorage.user ? "localStorage" : "sessionStorage";
            let strategy: IStorageStrategy = this.$getStorageStrategy();
            if (strategy instanceof WebStorageStrategy) {
                // setPersist is specific to WebStorageStrategy... Normally only the application that actually use the strategy would control this value; but,
                // migration needs to know incase if the old system was using persistent storage.
                strategy.setPersist(!!localStorage.user);
            }
            await this.$getStorageStrategy().save({
                [USER_KEY]: window[storage].user,
                [USER_FLAGS_KEY]: window[storage].user_flags
            });
            delete window.localStorage.user;
            delete window.sessionStorage.user;
            delete window.localStorage.user_flags;
            delete window.sessionStorage.user_flags;
        }
    }

    private $getStorageStrategy(): IStorageStrategy {
        return (ApplicationInstance.getInstance() as any).getStorageStrategy();
    }

    private $modifyUsers(users: unknown[]): void {
        for (let j = 0; j < users.length; j++) {
            let user: any = users[j];

            for (let i = 0; i < this.$organizationUsers.length; i++) {
                let oUser: any = this.$organizationUsers[i];

                if (user.token === oUser.token) {
                    oUser.f_name = user.firstName;
                    oUser.l_name = user.lastName;
                    oUser.role = user.role;
                }
            }
        }
    }

    protected override async _reset(): Promise<void> {
        this.$user = null;
        this.$shouldLogout = false;
        
        // why aren't we deleting user_flags here as well?
        // apparently user flags are saved on a per user basis

        this.$unit = UnitMode.METRIC;
        this.$organizationUsers = [];
        
        await this.$getStorageStrategy().delete(USER_KEY)
    }

    public isSuperUser(): boolean {
        if (!this.$user) {
            return false;
        }

        return this.$user.role === Role.ADMINISTRATOR;
    }

    public isAdministrator(): boolean {
        if (!this.$user) {
            return false;
        }
        
        const adminRoles = [
            Role.ADMINISTRATOR,
            Role.LOCAL_ADMINISTRATOR,
            Role.CUSTOMER_ADMINISTRATOR
        ];
        return adminRoles.indexOf(this.$user.role) > -1;
    }

    public isCustomerAdministrator(): boolean {
        if (!this.$user) {
            return false;
        }

        return this.$user.role === Role.CUSTOMER_ADMINISTRATOR;
    }

    public getOrganizationID(): number {
        return this.$user.organization.id;
    }

    public getOrganizationCurrency(): Currency {
        return this.$user.organization.settings.currency;
    }

    public getOrganizationCountry(): Country {
        return this.$user.organization.settings.country;
    }

    public isManager() {
        return this.$user.role === Role.DATA_MANAGER;
    }

    public getRole() {
        return this.$user.role;
    }

    public getUsers(): unknown[] {
        return (this.$organizationUsers || []).slice();
    }

    private $deleteUsers(tokens: string[]) {
        let newList = [];

        for (let i = 0; i < this.$organizationUsers.length; i++) {
            let user: any = this.$organizationUsers[i];
            if (tokens.indexOf(user.token) === -1) {
                newList.push(user);
            }
        }

        this.$organizationUsers = newList;
    }

    private $getFlags(): Record<string, boolean> {
        let token = this.getToken();
        if (!token) {
            return null;
        }

        let flags = JSON.parse(this.$getStorageStrategy().get(USER_FLAGS_KEY) || '{}');

        if (!flags[token]) {
            flags[token] = {};
        }

        return flags[token];
    }

    private async $saveUserFlags(flags: Record<string, boolean>): Promise<void> {
        let token = this.getToken();
        if (!token) {
            return null;
        }

        let userFlags = JSON.parse(this.$getStorageStrategy().get(USER_FLAGS_KEY) || '{}');

        userFlags[token] = flags;

        await this.$getStorageStrategy().save({[USER_FLAGS_KEY]: JSON.stringify(userFlags)});
        this._forceUpdate();
    }

    private $setUserFlag(flagName: string, flagValue: boolean) {
        let flags = this.$getFlags();
        flags[flagName] = flagValue;
        this.$saveUserFlags(flags);
    }

    public getUserFlag(flagName: string): boolean {
        return this.$getFlags()[flagName];
    }

    public hasUserFlag(flagName: string): boolean {
        let flag = this.$getFlags()[flagName];
        return flag !== undefined && flag !== null;
    }

    public getOrganizationName(): string {
        if (!this.$user) {
            return null;
        }

        return this.$user.organization.name;
    }

    public getOrganizationAccessLevel(): OrganizationAccessLevel {
        if (!this.$user) {
            return null;
        }
        return this.$user.organization.access;
    }

    public hasWorkplanAccess(): boolean {
        let result: boolean = false;

        if (this.getOrganizationAccessLevel() & OrganizationAccessLevel.WORKPLAN) {
            if (!this.$user.organization.workplanCusAdminOnly) {
                result = true;
            }
            else {
                if (this.isCustomerAdministrator() || this.isSuperUser()) {
                    result = true;
                }
            }
        }

        return result;
    }

    public isAccessValid(): boolean {
        let user = this.getUser();
        if (!user) {
            return false;
        }

        let access = user.access;

        if (!access) {
            return false;
        }

        let parts = access.split('.');
        let body;
        try {
            body = JSON.parse(window.atob(parts[1]));
        }
        catch (ex) {
            return false;
        }

        let expirationDate = ApplicationInstance.getInstance().getDateFactory().create(body.exp * 1000);
        return ApplicationInstance.getInstance().getDateFactory().create() < expirationDate;
    }

    public isRenewValid(): boolean {
        let user = this.getUser();
        if (!user) {
            return false;
        }

        let renew = user.renew;

        if (!renew) {
            return false;
        }

        let parts = renew.split('.');
        let body;
        try {
            body = JSON.parse(window.atob(parts[1]));
        }
        catch (ex) {
            return false;
        }

        let expirationDate = ApplicationInstance.getInstance().getDateFactory().create(body.exp * 1000);
        return ApplicationInstance.getInstance().getDateFactory().create() < expirationDate;
    }

    public getUser(): IAuthorizedUser {
        return this.$user;
    }

    public getToken(): string {
        if (this.$user) {
            return this.$user.token
        }
        return null;
    }

    public getAccessToken(): string {
        if (this.$user) {
            return this.$user.access;
        }
        return null
    }

    public getRenewToken(): string {
        if (this.$user) {
            return this.$user.renew;
        }
        return null
    }

    /**
     * @deprecated
     */
    public isManualPermitted(): boolean {
        if (!this.$user) {
            return false;
        }

        return this.$user.manual_permitted === 1;
    }

    public shouldLogout(): boolean {
        return this.$shouldLogout;
    }

    public getUnit(): UnitMode {
        return this.$unit;
    }

    public getDefaultNetworkView(): DefaultView {
        let user = this.getUser();
        if (!user) {
            return null;
        }

        return this.$user.organization.settings.defaultView;
    }

    public getCustomerAddress(): string {
        if (!this.$user) {
            return null;
        }

        return this.$user.organization.address;
    }

    //organization location. 
    public getCustomerPosition(): Position {
        if (!this.$user) {
            return null;
        }

        if (this.$user.organization.latitude === undefined || this.$user.organization.longitude === null) {
            return null;
        }
        else {
            return new Position({
                coords : {
                    latitude : this.$user.organization.latitude,
                    longitude : this.$user.organization.longitude
                },
                timestamp : ApplicationInstance.getInstance().getDateFactory().create().getTime()
            });
        }
    }

    /**
     * @deprecated - Use getPCISettings(PCIStandard.ASTM_D6433_REV_23) instead
     */
    public getSamplingMethod(): SamplingMethod {
        return this.$user.organization.settings.samplingMethod || SamplingMethod.ACCEPTABLE_ERROR;
    }

    /**
     * @deprecated - Use getPCISettings(PCIStandard.ASTM_D6433_REV_23) instead
     */
    public getAcceptableError() {
        let ae = this.$user.organization.settings.confidenceLevel;
        if (ae === undefined || ae === null) {
            ae = 5;
        }

        return ae;
    }

    public isIRIStrictMode(): 0 | 1 {
        return this.$user.organization.settings.mapMatchEnabled || 0;
    }

    private async $setUser(user: IAuthorizedUser): Promise<void> {
        this.$user = user;

        let sentry: ISentryClient<ITPError, unknown> = this.getSentryClient();
        if (sentry) {
            if (user) {
                sentry.setUser(user.id);
                sentry.setOrganizationID(user.organization.id);
                sentry.setOrganizationName(user.organization.name);
            }
            else {
                sentry.setUser(null);
                sentry.setOrganizationID(null);
                sentry.setOrganizationName(null);
            }
        }

        try {
            await this.$getStorageStrategy().save({[USER_KEY]: JSON.stringify(user)});
        }
        catch (ex) {
            TPError.wrap(ex).report();
        }

        this.$unit = user.organization.settings.units;
        this._forceUpdate();
    }

    protected override _update(actionData: IActionData): boolean {
        // let data = actionData.getData();
        let sentry: ISentryClient<ITPError, unknown> = this.getSentryClient();

        if (FlagUpdate.checkType(actionData)) {
            let data: IFlagUpdateActionOutput = actionData.getData();
            this.$setUserFlag(data.flagName, data.flagValue);
            return false;
        }
        else if (
            ResetPassword.checkType(actionData) ||
            ConfirmAccount.checkType(actionData) ||
            LoginAction.checkType(actionData)
        ) {
            this.$shouldLogout = false;
            let data: IResetPasswordOutput | IConfirmAccountOutput | ILoginActionOutput = actionData.getData();
            if (data.success) {
                this.$setUser(data.user);
            }
            else {
                this.$user = null;
            }
            return true;
        }
        else if (AccessTokenUpdate.getInstance().checkType(actionData)) {
            let data: IAuthorizedUser = (actionData.getData() as unknown as IAuthorizedUser); // TODO: Correct @totalpave/network to use the new interface
            this.$setUser(data);
            return false;
        }
        else if (actionData.getTag() === 'user_refresh') {
            // TODO: this action comes from PCI client's RefreshUserAction. The action should be moved somewhere accessible
            let user: any = (actionData.getData() as any)?.user;
            if (!user) {
                return true;
            }

            this.$unit = user.units;
            this.$user.organization.settings.units = user.units;
            this.$user.organization.settings.samplingMethod = user.sampling_method;
            this.$saveUser(this.$user);
            return false;
        }
        else if (actionData.getTag() === 'users_loaded') {
            // TODO: this action comes from Web Portal's LoadUsers action
            this.$organizationUsers = actionData.getData() as unknown[];
            return true;
        }
        else if (actionData.getTag() === 'create_user') {
            // TODO: This action comes from Web Portal's CreateUserAction
            this.$organizationUsers.push(actionData.getData() as unknown);
            return true;
        }
        else if (actionData.getTag() === 'delete_users') {
            // TODO: This action comes from Web Portal's DeleteUsers
            this.$deleteUsers(actionData.getData() as string[]);
            return true;
        }
        else if (actionData.getTag() === 'organization_settings_change') {
            // TODO: This action comes from Web Portal's SaveOrganizationSettings
            let data: any = actionData.getData();
            if (data.settings && data.settings.units !== undefined) {
                this.$unit = data.settings.units;
                return true;
            }
            return false;
        }
        else if (actionData.getTag() === 'user_settings_change') {
            // TODO: This action comes from Web Portal's SaveUserSettings
            let data: any = actionData.getData();
            if (data.firstName) {
                this.$user.fname = data.firstName;
            }
            if (data.lastName) {
                this.$user.lname = data.lastName;
            }
            this.$saveUser(this.$user);
            return false;
        }
        else if (ModifyUsers.checkType(actionData)) {
            this.$modifyUsers(actionData.getData().users);
            return true;
        }
        else if (LogoutAction.checkType(actionData)) {
            if (sentry) {
                sentry.setUser(null);
                sentry.setOrganizationID(null);
                sentry.setOrganizationName(null);
            }
            return true;
        }
        else if (RequestLogoutAction.checkType(actionData)) {
            this.$shouldLogout = true;
            return true;
        }

        return false;
    }

    private async $saveUser(user: IAuthorizedUser): Promise<void> {
        let sentry = this.getSentryClient();
        if (sentry) {
            sentry.setUser(user.id);
            sentry.setOrganizationID(user.organization.id);
            sentry.setOrganizationName(user.organization.name);
        }

        try {
            await this.$getStorageStrategy().save({[USER_KEY]: JSON.stringify(user)})
        }
        catch (ex) {
            TPError.wrap(ex).report();
        }

        this._forceUpdate();
    }

    public getPCISettings<T extends PCIStandard>(standard: T): IPCIConfigurationMap[T] {
        return this.$user.organization.settings.pci[standard];
    }

    public getEnabledPCIStandards(): PCIStandard[] {
        let standards: PCIStandard[] = [PCIStandard.ASTM_D6433_REV_23];

        let enabled: PCIStandard[] = [];
        for (let i: number = 0; i < standards.length; i++) {
            let s: PCIStandard = standards[i];
            let config = this.getPCISettings(s);
            
            if (config.vars.enabled) {
                enabled.push(config.standard);
            }
        }
        
        return enabled;
    }
}


import {Action} from './Action';
import {AuthClient} from '../net/AuthClient';
import { IAuthorizedUser } from '@totalpave/interfaces';

export interface IResetPasswordInput {
    code: string;
    password: string;
    confirm: string;
}

export interface IResetPasswordOutput {
    /**
     * @deprecated This action no longer dispatches on error, therefore the success field is redundent and will be removed in the future.
     */
    success: boolean;
    user: IAuthorizedUser;
}

class ResetPasswordSingleton extends Action<IResetPasswordInput, IResetPasswordOutput> {
    public override getTag(): string {
        return 'reset-password';
    }

    protected override _shouldDispatchOnError(): boolean {
        return false;
    }

    protected override async _execute(args: IResetPasswordInput): Promise<IResetPasswordOutput> {
        let user: IAuthorizedUser = await AuthClient.getInstance().resetPassword(args.code, args.password, args.confirm);
        return {
            success: true,
            user: user
        };
    }
}

export const ResetPassword = new ResetPasswordSingleton();


import {Iterator} from './Iterator';

export class Deque<TItem = any> {
    protected _data: Record<number, TItem>;
    protected _lowerBound: number;
    protected _upperBound: number;

    constructor() {
        this._data = {};
        this._lowerBound = 0;
        this._upperBound = 0;
    }

    public first(): TItem {
        let iterator: Iterator<TItem> = this.iterator();
        let item: TItem = null;
        do {
            item = iterator.next();
        } while (!iterator.hasReachedEnd() && (item === null || item === undefined));

        return item;
    }

    public last(): TItem {
        let iterator: Iterator<TItem> = this.reverseIterator();
        let item: TItem = null;
        do {
            item = iterator.next();
        } while (!iterator.hasReachedEnd() && (item === null || item === undefined));

        return item;
    }

    public getLowerBound(): number {
        return this._lowerBound;
    }

    public getUpperBound(): number {
        return this._upperBound;
    }

    public unshift(item: TItem): Deque<TItem> {
        this._lowerBound--;
        this._data[this._lowerBound] = item;
        return this;
    }

    public push(item: TItem): Deque<TItem> {
        this._upperBound++;
        this._data[this._upperBound] = item;
        return this;
    }

    /**
     * @returns Number of items in the Deque.
     * @example Deque.set(0, "jim"); Deque.set(5, "bob"); Deque.length() === 5; Deque.count() === 2;
     */
    public count(): number {
        return Object.keys(this._data).length;
    }

    /**
     * @returns Difference between lower bound and upper bound of the deque.
     * @example Deque.set(0, "jim"); Deque.set(5, "bob"); Deque.length() === 5; Deque.count() === 2;
     */
    public length(): number {
        return Math.abs(this._lowerBound - this._upperBound);
    }

    public set(index: number, item: TItem): Deque<TItem> {
        // JavaScript backwards compatibility
        index = parseInt(<string><any>index);
        if (isNaN(index)) {
            throw new Error('Index must be an integer.');
        }

        this._data[index] = item;
        this._syncBoundaries();

        return this;
    }

    public has(index: number): boolean {
        return this._data[index] !== undefined;
    }

    public get(index: number): TItem {
        return this._data[index];
    }

    public shift(): TItem {
        let data: TItem = this._data[this._lowerBound];
        delete this._data[this._lowerBound];
        this._lowerBound--;
        return data;
    }

    public pop(): TItem {
        let data: TItem = this._data[this._upperBound];
        delete this._data[this._upperBound];
        this._upperBound--;
        return data;
    }

    public iterate(fn: (item: TItem, index: number) => void): void {
        if (!fn) return null;

        for (let i = this.getLowerBound(); i <= this.getUpperBound(); i++) {
            fn(this.get(i), i);
        }
    }

    public reverseIterate(fn: (item: TItem, index: number) => void): void {
        if (!fn) return null;

        for (let i = this.getUpperBound(); i >= this.getLowerBound(); i--) {
            fn(this.get(i), i);
        }
    }

    public iterator(): Iterator<TItem> {
        let arr: TItem[] = [];

        for (let i = this._lowerBound; i <= this._upperBound; i++) {
            arr.push(this._data[i]);
        }

        return new Iterator<TItem>(arr);
    }

    public reverseIterator(): Iterator<TItem> {
        let arr: TItem[] = [];

        for (let i = this._lowerBound; i <= this._upperBound; i++) {
            arr.push(this._data[i]);
        }

        return new Iterator<TItem>(arr.reverse());
    }

    protected _syncBoundaries(): void {
        let keys = Object.keys(this._data);

        let lowest = Infinity, highest = -Infinity;

        for (let i = 0; i < keys.length; i++) {
            let index: number = parseInt(keys[i]);

            if (index < lowest) {
                lowest = index;
            }

            if (index > highest) {
                highest = index;
            }
        }

        this._lowerBound = lowest;
        this._upperBound = highest;
    }

    public toArray<TResult extends any[]>(
        /**
         * @deprecated No alternative, implement the recursive nature yourself.
         */
        recursive: boolean
    ): TResult {
        let arr: any[] = [];

        for (let i = this.getLowerBound(); i <= this.getUpperBound(); i++) {
            if (recursive) {
                let item = this.get(i);
                if (item instanceof Deque) {
                    arr.push((<Deque><any>item).toArray(recursive));
                }
                else {
                    arr.push(item);
                }
            }
            else {
                arr.push(this.get(i));
            }
        }

        return <TResult>arr;
    }
}

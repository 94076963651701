
import {Action} from '@totalpave/actions';
import {AuthClient} from '../net/AuthClient';

export interface IModifyUsersInput {
    users: unknown[];
}

export interface IModifyUsersOutput {
    users: unknown[];
}

class ModifyUsersSingleton extends Action<IModifyUsersInput, IModifyUsersOutput> {

    public override getTag(): string {
        return 'modify_users';
    }

    protected override _shouldDispatchOnError(): boolean {
        return false;
    }

    protected override async _execute(args: IModifyUsersInput): Promise<IModifyUsersOutput> {
        await AuthClient.getInstance().updateUsers(args.users);
        return {
            users: args.users
        };
    }
}

const ModifyUsers = new ModifyUsersSingleton();
export {ModifyUsers};

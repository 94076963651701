'use strict';

import * as React from 'react';
import Transition from 'react-transition-group/Transition';
import {
    ClassName,
    Header
} from '@totalpave/ui-core';
import {IconFactory} from '../factories/IconFactory';
import {ModalPopAction} from '../actions/ModalPopAction';
import {SnapUtils} from '../utils/SnapUtils';
import PropTypes from 'prop-types';
import "../style/Modal.less";
import {TPComponent} from './TPComponent';
import {TPError} from '@totalpave/error';
import { ApplicationInstance } from '@totalpave/application-instance';
import {Browser} from '../utils/Browser';

class Modal extends TPComponent {
    constructor(props) {
        super(props);

        this.$classNames = ["Modal"];
        this.hasFiredClassNameDeprecationWarning = false;
        
        this._cancel = this._cancel.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.close = this.close.bind(this);
        this._onBackgroundClick = this._onBackgroundClick.bind(this);
        this._snap = this._snap.bind(this);
        this._onScroll = this._onScroll.bind(this);
        this.state = {
            visible : this.props.visible,
            active: false
        };
        this._modalNode = null;
        this._contentNode = null;
    }

    isPersistent() {
        return this.props.persistent;
    }

    _getTransitionDuration() { //TO-DO add property for transition duration. Value will default to 100.
        return 100;
    }

    /**
     * 
     * @deprecated Call _addClassName inside the constructor instead. For class names that change based on state, move them onto your content divs.
     */
    _getClassName() {
        return '';
    }

    $getClassName() {
        let deprecatedClassName = this._getClassName();
        if (deprecatedClassName && !this.$hasFiredClassNameDeprecationWarning) {
            this.$hasFiredClassNameDeprecationWarning = true;
            ApplicationInstance.getInstance().getLogger().deprecated('Modal', '_getClassName', 'Modal', '_addClassName');
        }
        return this.$classNames.join(' ') + ' ' + deprecatedClassName;
    }

    _addClassName(name) {
        this.$classNames.push(name);
    }

    _render() {
        throw new Error('Abstract method _render() invoked.');
    }

    //public method
    getModalNode() {
        return this._getModalNode();
    }

    //protected method
    _getModalNode() {
        return this._modalNode;
    }

    _getContentNode() {
        return this._contentNode;
    }

    async close() {
        await this._willClose();
    }

    _willClose() {
        return new Promise((resolve, reject) => {
            let self = this;
            self.setState({
                active: false
            }, () => {
                setTimeout(() => { 
                    //setTimeout will make this reference window. 
                    self.willClose().then(() => {
                        ModalPopAction.execute().then(() => {
                            resolve();

                            if (self.props.onHide) {
                                self.props.onHide();
                            }
                        }).catch((error) => {
                            reject(new TPError({
                                message: "Modal._willClose errored out at ModalPopAction.",
                                stack: new Error().stack,
                                error: error
                            }));
                        });
                    });
                }, self.isSmooth() ? self._getTransitionDuration() : 1);
            });
        });
    }

    async willClose() {}

    getTitle() {
        throw new Error('getTitle is abstract.');
    }

    setVisible(state) {
        this.setState({
            visible : state
        });
    }

    isSmooth() {
        return true;
    }

    componentDidMount() {
        //Used to setup super.componentWillMount for extending Modals.
        if (this.isPopUp()) {
            window.addEventListener("scroll", this._onScroll);
            window.addEventListener("resize", this._snap);
        }

        if (this.isSmooth()) {
            this.setState({
                active: true
            });
        }
        this.props.onShow && this.props.onShow();
    }

    componentDidCatch() {
        this._removeEventListeners();
    }

    _removeEventListeners() {
        if (this.isPopUp()) {
            window.removeEventListener("scroll", this._onScroll);
            window.removeEventListener("resize", this._snap);
        }
    }

    componentWillUnmount() {
        this._removeEventListeners();

        this.props.onHide && this.props.onHide();
    }

    getCancelIcon() {
        return IconFactory.create(IconFactory.Icons.THIN_CANCEL, {
            key: 'cancel-icon',
            className: 'cancel-icon',
            onClick: () => {
                this._cancel();
            }
        });
    }

    _cancel() {
        this.onCancel();
        this.close();
    }

    //overridable function
    onCancel() {}

    isPopUp(){
        return false;
    }

    /**
     * @deprecated use shouldShowHeader instead
     */
    showHeader() {
        return this.shouldShowHeader();
    }

    shouldShowHeader() {
        return true;
    }

    /**
     * @deprecated Does nothing now, use showCancelButton instead
     */
    showBackButton() {
        return true;
    }

    /**
     * @deprecated use shouldShowCancelButton instead
     */
    showCancelButton() {
        return this.shouldShowCancelButton();
    }

    shouldShowCancelButton() {
        return true;
    }

    _onScroll() {
        this._snap();
    }

    componentDidUpdate() {
        this._snap();
    }

    setPointer(pointer) {
        this.pointerNode = pointer;
    }

    getPointer() {
        return this.pointerNode;
    }

    _getPointerOpts() {
        return null;
    }

    _snap(){
        if (this.isPopUp() && this._contentNode) {
            //SnapUtils.snap(this._contentNode, this.props.anchorParent, this.props.alignment, null, this.getPointer());
            SnapUtils.snap({
                subject: this._contentNode,
                target: this.props.anchorParent,
                alignment: this.props.alignment,
                pointerOpts: this._getPointerOpts() ? {} : null
            });
        }
    }

    shouldCloseOnBackgroundClick() {
        return true;
    }

    _onBackgroundClick(event) {
        event.stopPropagation()

        if (this.shouldCloseOnBackgroundClick()) {
            this._cancel();
        }
    }

    getLeftIcons() {
        return null;
    }

    isFlex() {
        return true;
    }

    isHeaderCentered() {
        return false;
    }

    /**
     * Note: Modals creates confusion and cannot
     * properly support the history stack system
     * since they do not have any View representation
     * in the router. Fullscreen UI should be implemented
     * as a View that you route to instead.
     * @returns {Boolean}
     */
    isFullScreen() {
        return this.props.fullscreen;
    }

    //Should return array of icons.
    getRightIcons() {
        return (this.props.rightIcons ? this.props.rightIcons : null);
    }

    _getContentStyle() {
        return {};
    }

    _isTopSafeAreaInsetBarEnabled() {
        return true;
    }    
    _isRightSafeAreaInsetBarEnabled() {
        return true;
    }    
    _isBottomSafeAreaInsetBarEnabled() {
        return true;
    }    
    _isLeftSafeAreaInsetBarEnabled() {
        return true;
    }

    /**
     * 
     * Override to return false to disable old javascript inset system.
     * 
     * @returns boolean
     */
    _useInsetsStrategy() {
        return true;
    }

    _renderTopSafeAreaInsetBarContent() {
        return <div className="statusbar-fill"></div>;
    }

    _renderRightSafeAreaInsetBarContent() {}
    _renderBottomSafeAreaInsetBarContent() {}
    _renderLeftSafeAreaInsetBarContent() {}

    _renderModal(style) {
        let header = null;

        let rightIcons = this.getRightIcons();
        if (!rightIcons) {
            rightIcons = [];
        }

        let cancelButton = this.shouldShowCancelButton() ? this.getCancelIcon() : null;
        if (cancelButton) {
            rightIcons.push(cancelButton);
        }

        if (this.shouldShowHeader()) {
            header = <Header
                leftIcons={this.getLeftIcons()}
                rightIcons={rightIcons}
                title={this.getTitle()}
                centered={this.isHeaderCentered()}
            />;
        }

        if (header === null && this.getLeftIcons() !== null){
            header = <div className="icons">
                {this.getLeftIcons()}{rightIcons}
            </div>;
        }

        let initStyle = {};

        if (this.isSmooth()) {
            initStyle = {
                transition: `opacity ${this._getTransitionDuration()}ms ease-in-out`,
                opacity: 0
            };
        }

        if (!style) {
            style = {};
        }
        
        let contentStyle = {};
        // safe area inset bar styles
        let topStyles = {};
        let rightStyles = {};
        let bottomStyles = {};
        let leftStyles = {};

        if (this._useInsetsStrategy()) {
            let contentInsets = ApplicationInstance.getInstance().getInsetStrategy().execute();
            contentStyle.marginTop = contentInsets.top;
            contentStyle.marginBottom = contentInsets.bottom;

            if (this.isFullScreen()) {
                contentStyle.marginRight = contentInsets.right;
                contentStyle.marginLeft = contentInsets.left;
            }

            if (Browser.getOS() === Browser.ANDROID) {
                topStyles.height = this._isTopSafeAreaInsetBarEnabled() ? contentInsets.top : 0;
                rightStyles.width = this._isRightSafeAreaInsetBarEnabled() ? contentInsets.right : 0;
                bottomStyles.height = this._isBottomSafeAreaInsetBarEnabled() ? contentInsets.bottom : 0;
                leftStyles.width = this._isLeftSafeAreaInsetBarEnabled() ? contentInsets.left : 0;
            }
        }

        contentStyle = {
            ...contentStyle,
            ...this._getContentStyle()
        };

        return (
            <div 
                className={ClassName.execute({
                    'popup': this.isPopUp(),
                    'flex': this.isFlex(),
                    'hasCancelButton': this.shouldShowCancelButton(),
                    'fullscreen': this.isFullScreen()
                }, this.$classNames)}
                ref={(c) => { this._modalNode = c; }} 
                onClick={this._onBackgroundClick}
                style={{
                    ...initStyle,
                    ...style
                }}
            >
                <div
                    className="content"
                    ref={(c) => { this._contentNode = c; }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    style={contentStyle}
                >
                    {header}
                    {this._render()}
                </div>
                <div
                    key="top"
                    className="safe-area-inset-bar top-safe-area-inset-bar"
                    style={topStyles}
                >
                    {this._renderTopSafeAreaInsetBarContent()}
                </div>
                <div
                    className="safe-area-inset-bar right-safe-area-inset-bar"
                    style={rightStyles}
                >
                    {this._renderRightSafeAreaInsetBarContent()}
                </div>
                <div
                    className="safe-area-inset-bar bottom-safe-area-inset-bar"
                    style={bottomStyles}
                >
                    {this._renderBottomSafeAreaInsetBarContent()}
                </div>
                <div
                    className="safe-area-inset-bar left-safe-area-inset-bar"
                    style={leftStyles}
                >
                    {this._renderLeftSafeAreaInsetBarContent()}
                </div>
            </div>
        );
    }

    _renderSmoothModal() {
        const transStyle = {
            entering: {opacity: 0},
            entered : {opacity: 1}
        };

        return (
            <Transition
                in={this.state.active === undefined ? true : this.state.active}
                timeout={{
                    enter: 1,
                    exit: this._getTransitionDuration()
                }}
                appear
            >
                {(state) => {
                    return this._renderModal(transStyle[state]);
                }}
            </Transition>
        );
    }

    render() {
        if (this.isSmooth()) {
            return this._renderSmoothModal();
        }
        else {
            return this._renderModal();
        }
    }
}

Modal.propTypes = {
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    anchorParent: PropTypes.instanceOf(Element),
    alignment: PropTypes.string,
    fullscreen: PropTypes.bool,
    rightIcons: PropTypes.arrayOf(PropTypes.node),
    /*
        Persistent Modals should not be dismissed automatically by the system.
        For example, functionalities like clearing stores on logout should not remove persistent modals from the ModalStore.
        This property should not be used without good reason.
        In addition, take care when using persistent with events such as onHide and onShow as the event may be fired 
        during what would normally be unexpected circumstances that is allowed due to the modal not being auto dismissed by
        the system, such as after logout.
    */
    persistent: PropTypes.bool
};

export { Modal };

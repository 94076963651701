
import {Action} from '@totalpave/actions';

import {AuthClient} from '../net/AuthClient';
import { IAuthorizedUser } from '@totalpave/interfaces';

export interface IConfirmAccountInput {
    code: string;
    password: string;
    confirm: string;
}

export interface IConfirmAccountOutput {
    /**
     * @deprecated This action no longer dispatches on error, therefore the success field is redundent and will be removed in the future.
     */
    success: boolean;
    user: IAuthorizedUser;
}

class ConfirmAccountSingleton extends Action<IConfirmAccountInput, IConfirmAccountOutput> {
    public override getTag(): string {
        return 'confirm-account';
    }

    protected override _shouldDispatchOnError(): boolean {
        return false;
    }

    protected override async _execute(args: IConfirmAccountInput): Promise<IConfirmAccountOutput> {
        let user: IAuthorizedUser = await AuthClient.getInstance().confirmAccount(args.code, args.password, args.confirm);
        return {
            success: true,
            user: user
        };
    }
}

export const ConfirmAccount = new ConfirmAccountSingleton();

'use strict';

import Bowser from '@totalpave/bowser';

let browser = Bowser.getParser(window.navigator.userAgent);

const INTERNET_EXPLORER = 'Internet Explorer';
const CHROME = 'Chrome';
const FIREFOX = 'Firefox';
const SAFARI = 'Safari';
const OPERA = 'Opera';
const EDGE = 'Microsoft Edge';

export interface IVersion {
    major: number;
    minor: number;
}

const PARSE_VERSION = function(v: string): IVersion {
    let p = v.toString().split('.');
    let major = p[0];
    let minor = p[1];
    return {
        major : parseInt(major),
        minor : parseInt(minor)
    };
}

export enum InternetBrowser {
    IE          = 'msie',
    CHROME      = 'chrome',
    OPERA       = 'opera',
    EDGE        = 'msedge',
    SAFARI      = 'safari',
    FIREFOX     = 'firefox',
    UNKNOWN     = 'unknown'
}

export class Browser {
    static overrideUserAgent(userAgent: string): void {
        browser = Bowser.getParser(userAgent);
    }

    static getOS(): string {
        return browser.getOSName();
    }

    /**
     * @returns true if IE.
     */
    static isIE(): boolean {
        return browser.getBrowserName() === INTERNET_EXPLORER;
    }

    /**
     * Returns true if desktop or desktop like.
     */
    static isDesktop(): boolean {
        return browser.getPlatformType() === 'desktop';
    }

    /**
     * Returns true if tablet
     */
    static isTablet(): boolean {
        return browser.getPlatformType() === 'tablet';
    }

    /**
     * Returns true if mobile phone
     */
    static isPhone(): boolean {
        return browser.getPlatformType() === 'mobile';
    }

    /**
     * Returns true if mobile phone OR tablet.
     */
    static isMobile(): boolean {
        return Browser.isTablet() || Browser.isPhone();
    }

    /**
     * Returns true if running in a native application shell
     * Currently this tests for the presence of cordova,
     * but this may test for other things in the future
     */
    static isApplication(): boolean {
        return !!((window as any).cordova);
    }

    /**
     * Returns true if running as a website.
     * This is effectively the opposite of isApplication()
     */
    static isSite(): boolean {
        return !Browser.isApplication();
    }

    static getBrowserCode(): string {
        switch (browser.getBrowserName()) {
            case INTERNET_EXPLORER: return InternetBrowser.IE;
            case CHROME: return InternetBrowser.CHROME;
            case OPERA: return InternetBrowser.OPERA;
            case SAFARI: return InternetBrowser.SAFARI;
            case FIREFOX: return InternetBrowser.FIREFOX;
            case EDGE: return InternetBrowser.EDGE;
            default: return InternetBrowser.UNKNOWN;
        }
    }

    static getVersion(): IVersion {
        let v = browser.getBrowserVersion();

        if (!v) {
            // browser doesn't supply a version for safari since it is the same as the iOS OS version.
            v = browser.getOSVersion();
        }

        if (!v) {
            // If we still don't have a version, default to 0.0.0
            v = '0.0.0';
        }
        
        return PARSE_VERSION(v);
    }
}


import {Action} from '@totalpave/actions';
import {AuthClient} from '../net/AuthClient';
import {Localization} from '@totalpave/localization';
import { IAuthorizedUser } from '@totalpave/interfaces';
import { TPError } from '@totalpave/error';

export interface ILoginActionInput {
    email: string;
    password: string;
    persist?: boolean;
}

export interface ILoginActionOutput {
    /**
     * @deprecated This action no longer dispatches on error, therefore the success field is redundent and will be removed in the future.
     */
    success: boolean;
    user: IAuthorizedUser;
    persist: boolean;
}

/*
Interfaces for the Login action
 */
class LoginActionSingleton extends Action<ILoginActionInput, ILoginActionOutput> {
    public override getTag(): string {
        return 'login_action';
    }

    protected override _shouldDispatchOnError(): boolean {
        return false;
    }

    /**
     * public execute
     * @param  {String} email 
     * @param  {String} password 
     * @param  {Boolean} persist
     * @return {void} 
     */
    protected override async _execute(args: ILoginActionInput): Promise<ILoginActionOutput> {
        let localization: Localization = Localization.getInstance();
        let message: string;
        if (!args.email) {
            if (localization.hasKey('tp-app-common/LoginForm/missing-email')) {
                message = localization.resolve('tp-app-common/LoginForm/missing-email');
            }
            else {
                message = 'Please enter your email';
            }
            throw new TPError(message);
        }

        if (!args.password) {
            if (localization.hasKey('tp-app-common/LoginForm/missing-password')) {
                message = localization.resolve('tp-app-common/LoginForm/missing-password');
            }
            else {
                message = 'Please enter your password';
            }
            throw new TPError(message);
        }

        let user: IAuthorizedUser = await AuthClient.getInstance().login(args.email, args.password);
        return {
            user: user,
            success: true,
            persist: !!args.persist
        };
    }
}

const LoginAction = new LoginActionSingleton();

export { LoginAction };

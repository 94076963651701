
import {Action} from '@totalpave/actions';
import {Store} from  '@totalpave/store';

//Tells the app we are logging out right NOW.
class LogoutActionSingleton extends Action<void, void> {
    public constructor() {
        super();
        this.setForceExecute(true);
    }

    public override getTag(): string {
        return 'logout_action';
    }

    protected override _shouldDispatchOnError(): boolean {
        return false;
    }

    /**
     * public logout
     *
     *  This will clear the user's local storage.
     * 
     * @return {void} 
     */
    protected override async _execute(): Promise<void> {
        await Promise.all(
            Store.getInstances().map((store) => {
                return store.reset();
            })
        );
    }
}

const LogoutAction = new LogoutActionSingleton();

export { LogoutAction };

'use strict';

import pkg from '../package.json';

const version = pkg.version;

export {version};

//Application Abstract
export {Application} from './app/Application.js';
export {ConfigLoader} from './app/ConfigLoader.js';

// Event
export * from './event/Events.js';
export {CustomEvent} from './event/CustomEvent.js';
export {MouseOutOfWindowEvent} from './event/MouseOutOfWindowEvent.js';

//Stores
export {ModalStore} from './store/ModalStore.js';
export {NetworkStore} from '@totalpave/network';
export {MenuStore} from './store/MenuStore.js';
export {MessageStore} from './store/MessageStore.js';
export {UserStore} from './store/UserStore';
export {PreferenceStore} from './store/PreferenceStore.js';
export {MouseStore} from './store/MouseStore.js';
export {ViewportStore} from './store/ViewportStore';
export {ActionStore} from './store/ActionStore.js';
export {MetadataStore} from './store/MetadataStore.js';
export {DragStore} from './store/DragStore.js';
export {ProgressStore} from './store/ProgressStore.js';
export {LocalizationStore} from './store/LocalizationStore.js';
export {ToolTipStore} from './store/ToolTipStore.js';

//Components
export {UIPlaceholder} from './components/UIPlaceholder.js';
export {AppLoader} from './components/AppLoader.js';
export {LabeledCheckbox} from './components/LabeledCheckbox.js';
export {ConfirmModal} from './components/ConfirmModal.js';
export {AlertModal} from './components/AlertModal.js';
export {LoginForm} from './components/LoginForm.js';
export {SlideMenu} from './components/SlideMenu.js';
export {Frame} from './components/Frame.js';
export {Label} from './components/Label.js';
export {ErrorScreen} from './components/ErrorScreen.js';
export {LoginScreen} from './components/LoginScreen.js';
export {ResetPasswordModal} from './components/ResetPasswordModal.js';
export {TPComponent} from './components/TPComponent.js';
export {ComponentContainer} from './components/ComponentContainer.js';
export {ModalContainer} from './components/ModalContainer.js';
export {Icon} from './components/Icon.js';
export {FontAwesomeIcon} from './components/FontAwesomeIcon';
export {SVGIcon} from './components/SVGIcon';
export {Button} from './components/Button.js';
export {Header} from './components/Header.js';
export {Modal} from './components/Modal.js';
export {FormModal} from './components/FormModal.js';
export {BreadcrumbModal} from './components/BreadcrumbModal.js';
export {ErrorModal} from './components/ErrorModal.js';
export {Message} from './components/Message.js';
export {MessageContainer} from './components/MessageContainer.js';
export {MenuContainer} from './components/MenuContainer.js';
export {BaseScreen, IBaseScreenProps} from './components/BaseScreen';
export {LogoutComponent} from './components/LogoutComponent.js';
export {SplashSpinner} from './components/SplashSpinner';
export {Spinner} from './components/Spinner.js';
export {MarkerColorPickerModal} from './components/MarkerColorPickerModal.js';
export {ResizableComponent} from './components/ResizableComponent.js';
export {Checkbox} from './components/Checkbox.js';
export {Table} from './components/Table.js';
export {Select} from './components/Select.js';
export {Textfield, InputMode} from './components/Textfield.js';
export {NumberFormatTextfield} from './components/NumberFormatTextfield.js';
export {Menu} from './components/Menu.js';
export {VirtualTable} from './components/VirtualTable.js';
export {VirtualPaginatedTable} from './components/VirtualPaginatedTable.js';
export {DeleteModal} from './components/DeleteModal.js';
export {TabView} from './components/TabView.js';
export {ToggleGroup} from './components/ToggleGroup.js';
export {Dropzone} from './components/Dropzone.js';
export {DraggableComponent} from './components/DraggableComponent.js';
export {DroppableComponent} from './components/DroppableComponent.js';
export {Progressbar} from './components/Progressbar.js';
export * from './components/Breadcrumb.js';
export * from './components/InlineBlock.js';
export * from './components/DatePicker.js';
export {MenuItem} from './components/MenuItem.js';
export {GroupedMenuItem} from './components/GroupedMenuItem.js';
export {BaseList} from './components/BaseList.js';
export {List} from './components/List.js';
export {SimpleList} from './components/SimpleList.js';
export {ListItem} from './components/ListItem.js';
export {Searchfield} from './components/Searchfield.js';
export {Interval} from './components/Interval.js';
export {Calendar} from './components/Calendar.js';
export {CalendarModal} from './components/CalendarModal.js';
export {ProgressBarModal} from './components/ProgressBarModal.js';
export {CircleProgressBar} from './components/CircleProgressBar.js';
export {HandleBar} from './components/HandleBar.js';
export {Pipe} from './components/Pipe.js';
export {BulkEditModal} from './components/BulkEditModal.js';
export {PaginatedList} from './components/PaginatedList.js';
export {BlankListItem} from './components/BlankListItem.js';
export {ToggleBar} from './components/ToggleBar.js';
export {ToolTipContainer} from './components/ToolTipContainer.js';
export {ToolTip} from './components/ToolTip.js';
export {InfoIcon} from './components/InfoIcon.js';
export {ComparisonComponent} from './components/ComparisonComponent.js';
export {Form} from './components/Form.js';
export {FormItem} from './components/FormItem.js';
export {FormGroup} from './components/FormGroup.js';
export {FormItemTextfield} from './components/FormItemTextfield.js';
export {FormItemSelect} from './components/FormItemSelect.js';
export {FormItemToggleGroup} from './components/FormItemToggleGroup.js';
export {FormItemLabeledCheckbox} from './components/FormItemLabeledCheckBox.js';
export {FormItemDatePicker} from './components/FormItemDatePicker.js';
export {FormItemToggleBar} from './components/FormItemToggleBar.js';
export {UnitValue} from './components/UnitValue.js';

//Constants
export {
    Constants,
    TRANSITION_DURATION,
    TRANSITION_STRATEGY
} from './utils/Constants.js';

//Actions
export {Action} from './actions/Action';
export {ActionData} from './actions/ActionData';
export {LoadCurrencyLocalization} from './actions/LoadCurrencyLocalization';
export {ConfirmAccount} from './actions/ConfirmAccount';
export {ResetPassword} from './actions/ResetPassword';
export {SetMetadataAction} from './actions/SetMetadataAction.js';
export {AppGeometryUpdate} from './actions/AppGeometryUpdate.js';
export {ModalPopAction} from './actions/ModalPopAction.js';
export {ModalPushAction} from './actions/ModalPushAction.js';
export {ClearMenusAction} from './actions/ClearMenusAction.js';
export {ClearMessageAction} from './actions/ClearMessageAction.js';
export {AccessTokenUpdate} from './actions/AccessTokenUpdate';
export {SlowNetwork} from './actions/SlowNetwork.js';
export {LoginAction} from './actions/LoginAction';
export {LogoutAction} from './actions/LogoutAction';
export {RequestLogoutAction} from './actions/RequestLogoutAction';
export {DispatchMessage} from './actions/DispatchMessage.js';
export {ModifyUsers} from './actions/ModifyUsers';
export {LoadOrganizationPreferences} from './actions/LoadOrganizationPreferences.js';
export {SaveOrganizationPreference} from './actions/SaveOrganizationPreference.js';
export {SaveOrganizationPreferences} from './actions/SaveOrganizationPreferences.js';
export {MouseMoveAction} from './actions/MouseMoveAction.js';
export {MouseButtonDownAction} from './actions/MouseButtonDownAction.js';
export {MouseButtonUpAction} from './actions/MouseButtonUpAction.js';
export {ViewportSizeUpdate} from './actions/ViewportSizeUpdate';
export {AddMenuAction} from './actions/AddMenuAction.js';
export {DeleteMenuAction} from './actions/DeleteMenuAction.js';
export {DeleteOrganizationPreference} from './actions/DeleteOrganizationPreference.js';
export {FlagUpdate} from './actions/FlagUpdate';
export {PickUpDroppable} from './actions/PickUpDroppable.js';
export {RenewTokenAction} from './actions/RenewTokenAction.js';
export {AddBodyClass} from './actions/AddBodyClass.js';
export {RemoveBodyClass} from './actions/RemoveBodyClass.js';
export {SetDropzone} from './actions/SetDropzone.js';
export * from './actions/GetCountryList.js';
export * from './actions/GetCurrencyList.js';
export {UpdateProgressAction} from './actions/UpdateProgressAction.js';
export {ClearProgressAction} from './actions/ClearProgressAction.js';
export {UpdateOrientation} from './actions/UpdateOrientation';
export {ShowToolTip} from './actions/ShowToolTip.js';

// Builders
export {MenuBuilder} from './builders/MenuBuilder.js';
export {ListRowBuilder} from './builders/ListRowBuilder.js';
export {ComparisonParamsBuilder} from './builders/ComparisonParamsBuilder.js';
export {StringComparisonParamsBuilder} from './builders/StringComparisonParamsBuilder.js';
export {DateComparisonParamsBuilder} from './builders/DateComparisonParamsBuilder.js';
export {SelectComparisonParamsBuilder} from './builders/SelectComparisonParamsBuilder.js';
export {BooleanComparisonParamsBuilder} from './builders/BooleanComparisonParamsBuilder.js';
export {NumberComparisonParamsBuilder} from './builders/NumberComparisonParamsBuilder.js';
export {AbstractCrumbBuilder} from './builders/AbstractCrumbBuilder.js';
export {TextualCrumbBuilder} from './builders/TextualCrumbBuilder.js';
export {TextfieldPropsBuilder} from './builders/TextfieldPropsBuilder.js';
export {CurrencyTextfieldPropsBuilder} from './builders/CurrencyTextfieldPropsBuilder.js';
export {EmailTextfieldPropsBuilder} from './builders/EmailTextfieldPropsBuilder.js';
export {PasswordTextfieldPropsBuilder} from './builders/PasswordTextfieldPropsBuilder.js';
export {DecimalTextfieldPropsBuilder} from './builders/DecimalTextfieldPropsBuilder.js';
export {NumberTextfieldPropsBuilder} from './builders/NumberTextfieldPropsBuilder.js';
export {NumberFormatTextfieldPropsBuilder} from './builders/NumberFormatTextfieldPropsBuilder.js';

//factories
export {Factory} from './factories/Factory.js';
export {IconFactory} from './factories/IconFactory.js';
export {UIFactory} from './factories/UIFactory.js';
export {DefaultUIFactory} from './factories/DefaultUIFactory.js';
export {AbstractEditorFactory} from './factories/AbstractEditorFactory.js';
export {TableEditorFactory} from './factories/TableEditorFactory.js';
export {DefaultTableEditorFactory} from './factories/DefaultTableEditorFactory.js';
export {NotificationFactory} from './factories/NotificationFactory.js';
export {DefaultNotificationFactory} from './factories/DefaultNotificationFactory.js';
export {TableDefinitionFactory} from './factories/TableDefinitionFactory.js';
export {DateFactory} from './factories/DateFactory.js';
export {JSDateFactory} from './factories/JSDateFactory.js';
export {TextfieldFactory} from './factories/TextfieldFactory.js';

//Strategies
export {StorageStrategy} from './strategies/StorageStrategy';
export {WebStorageStrategy} from './strategies/WebStorageStrategy';
export {FilterStrategy} from './strategies/FilterStrategy.js';
export {IInsetResult, InsetStrategy} from './strategies/InsetStrategy';

//FileSystem
export { FileSystem, FileSystemPath } from './fs/FileSystem.js';
//These classes are internal classes used by FileSystem. There is no reason to export them.
//export {DeleteFileTask} from './fs/DeskFileTask';
//export {ReadFileTask} from './fs/ReadFileTask';
//export {UpdateFileTask} from './fs/UpdateFileTask';

//model
export {MessageModel} from './model/MessageModel.js';
export {Model} from './model/Model.js';
export {Position} from './model/Position.js';
export {QuadBounds} from './model/QuadBounds.js';

//net
export {HTTPConstants} from './net/HTTPConstants.js';
export {NetworkClient} from './net/NetworkClient.js';
export {AuthClient} from './net/AuthClient.js';
export {IRIClient} from './net/IRIClient.js';
export {PCIClient} from './net/PCIClient.js';
export {OrganizationClient} from './net/OrganizationClient.js';
export {FileStoreClient} from './net/FileStoreClient.js';
export {AppSupportClient} from './net/AppSupportClient.js';

//Utilities
export {InputType} from './utils/InputType.js';
export {Version, IS_NEWER, IS_OLDER, IS_EQUAL} from './utils/Version.js';
export {ComponentCache} from './utils/ComponentCache.js';
export {Browser} from './utils/Browser.js';
export {Logger} from './utils/Logger.js';
export {Entities} from './utils/Entities';
export {NodeGeometry} from './utils/NodeGeometry.js';
export {Iterator} from './utils/Iterator';
export {ObjectUtils} from './utils/ObjectUtils.js';
export {DateFormatter} from './utils/DateFormatter.js';
export {Color} from './utils/Color.js';
export {ClassName} from './utils/ClassName';
export {Deque} from './utils/Deque';
export {DictionaryIterator} from './utils/DictionaryIterator.js';
export {EmailValidator} from './utils/EmailValidator.js';
export {SnapUtils} from './utils/SnapUtils.js';
export {PasswordValidator} from './utils/PasswordValidator.js';
export {Immutable} from './utils/Immutable.js';
export {SlabUtils} from './utils/SlabUtils.js';
export {SurfaceType} from './utils/SurfaceType.js';
export {KeyboardListener} from './utils/KeyboardListener.js';
export {KeyCode} from './utils/KeyCode.js';
export {Node} from './utils/Node.js';
export {SVGUtils} from './utils/SVGUtils';
export {MathUtils} from './utils/MathUtils';
export {TrackerUtils} from './utils/TrackerUtils.js';
export {Batcher} from './utils/Batcher.js';
export {CSSClassUtils} from './utils/CSSClassUtils.js';
export {StylesheetManager} from './utils/StylesheetManager.js';
export {MouseButton} from './utils/MouseButton.js';
export {ScrollbarMeasurement} from './utils/ScrollbarMeasurement.js';
export {TextMeasurer} from './utils/TextMeasurer';
export {OrderFilter} from './utils/OrderFilter.js';
export {QueryFilter} from './utils/QueryFilter.js';
export {RotatingColorTable} from './utils/RotatingColorTable';
export {Scroller} from './utils/Scroller.js';
export {ChunkLoader} from './utils/ChunkLoader.js';
export * from './utils/Country.js';
export * from './utils/Currency.js';
export * from './utils/DistressUtils.js';
export * from './utils/Formatter.js';
export {SafeAreaInsetMeasurement} from './utils/SafeAreaInsetMeasurement.js';
export {SectionUtils} from './utils/SectionUtils.js';
export {Zipper} from './utils/Zipper.js';
export {Option} from './utils/Option.js';
export {SelectOption} from './utils/SelectOption.js';
export {OptionGroup} from './utils/OptionGroup.js';
export {Serializer} from './utils/Serializer.js';
export {DateUtils} from './utils/DateUtils.js';
export {FuzzySearch} from './utils/FuzzySearch.js';
export {WorkTypeUnit} from './utils/WorkTypeUnit.js';
export {DataParser} from './utils/DataParser.js';
export {AnchorPosition} from './utils/AnchorPosition.js';
export {NumberGroupStyle} from './utils/NumberGroupStyle.js';
export {CurrencyFormatter} from './utils/CurrencyFormatter.js';
export {TableNumberType} from './utils/TableNumberType.js';
export {IRIUtils} from './utils/IRIUtils.js';
export {Unit} from './utils/Unit.js';
export {OrientationType} from './utils/OrientationType';

//Queue
export { Queue, QueueEvents } from './queue/Queue.js';
export { Task, TaskStatus, TaskEvents } from './queue/Task.js';

//Transitions
export { TransitionContract } from './transitions/TransitionContract';
export { TransitionExpand } from './transitions/TransitionExpand';
export { TransitionFadeOut } from './transitions/TransitionFadeOut';

//Resources
export {TPLogo} from './components/TPLogo';
import R_ARROW from './assets/inline/arrow.svg';
import R_TPLOGO from './assets/deferred/tplogo.png';
import R_PLUS_CIRCLE_THIN from './assets/deferred/plus_circle_thin.svg';
import R_LONG_PRESS_HAND from './assets/deferred/long_press_hand.svg';
import R_LOGO_WHITE from './assets/deferred/logowhite.svg';
import R_LOCATION_ACTIVE from './assets/deferred/location_icon_active.svg';
import R_LOCATION_UNACTIVE from './assets/deferred/location_icon_unactive.svg';
import R_CIRCULAR_LOGO from './assets/deferred/CircularLogo.svg';
import R_CANCEL_CIRCLE_THIN from './assets/deferred/cancel_circle_thin.svg';


export {
    R_ARROW,
    R_TPLOGO,
    R_PLUS_CIRCLE_THIN,
    R_LOCATION_ACTIVE,
    R_LOCATION_UNACTIVE,
    R_LONG_PRESS_HAND,
    R_LOGO_WHITE,
    R_CIRCULAR_LOGO,
    R_CANCEL_CIRCLE_THIN
};
